.complienceHeaderPopup {
  position:fixed;
  overflow: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 40px;
  background-color: #fff;
  text-align: left;
  z-index: 10;
}