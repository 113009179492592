.checkBox {
  line-height: 20px;
  cursor: pointer;
  &::before {
    color: rgba(0, 0, 0, .1);
    content: '✓';
    line-height: 20px;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 4px;
  }
  &:hover::before {
    color: #333;
    content: '✓';
  }
}

.checkBoxChecked {
  &::before {
    content: '✓';
    border: 1px solid #000;
    width: 20px;
    height: 20px;
  }
}

.deleteBox {
  line-height: 20px;
  cursor: pointer;
  &::before {
    content: '✖';
    color: rgba(0, 0, 0, .1);
    line-height: 20px;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 4px;
  }
  &:hover::before {
    content: '✖';
    color: #333;
  }
}

.text-align-right {
  text-align: right;
}

.alert-box {
  border: 1px solid #a33;
  background-color: #faa;
  color: #600;
  padding: 14px;
  margin-top: 16px;
  margin-bottom: 16px;

}

.info-box {
  border: 1px solid #6f6;
  background-color: #afa;
  color: #060;
  padding: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.page-header {
  text-align: right;
  a {
    margin: 2px;
  }
}

.w3-button.w3-green {
  border-radius: 10px;
  &:hover {
    color: #000 !important;
    background-color: rgb(229, 250, 215) !important;
  }
}

.w3-button.w3-yellow {
  border-radius: 10px;
  &:hover {
    color: #000 !important;
    background-color: rgb(249, 255, 128) !important;
  }
}

@media print {
  .bookingSuccess {
    color: #000 !important;
  }
  .dontPrint {
    display: none;
  }
}